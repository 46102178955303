import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
} from "../../util";

let elMap: {
  projectShortText: HTMLElement;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    projectShortText: document.getElementById("project-short-text"),
  };

  addAnimationClassOncePartiallyVisible(
    elMap.projectShortText,
    "animate__backInLeft"
  );
}
